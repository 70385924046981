@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sometype+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700');



@layer base {
    body {
        font-family: 'Open Sans';
        /* font-family: 'Sometype Mono', monospace; */
        background-size: cover;
        background-color: #111111;
    }
    td {
        max-width: 15rem;
        overflow: hidden;
    }
  }